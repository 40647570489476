class MatrizService {
  constructor() {}
  resources = () => ({
    listarCecos: {
      uri: `/v1/presupuesto/listar-ceco-budget`,
      method: ['get']
    },
    exportarCecos: {
      uri: `/v1/presupuesto/exportar-ceco-budget`,
      method: ['get']
    },
    exportarModeloCecos: {
      uri: `/v1/presupuesto/descargar-formato-ceco-budget`,
      method: ['get']
    },
    importarCecos: {
      uri: `/v1/presupuesto/importar-ceco-budget`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    },
    permisos: {
      uri: `/v1/usuario/permisos`,
      method: ['get']
    }
  });
}

export default MatrizService;
